body {
  margin: 40px;
  font-family: 'IBM Plex Sans';
  color: #333;
  background: rgb(248, 248, 248);
}

.App {
}

.App-header {
}

h1 {
  font-size: 14vw;
  text-transform: uppercase;
  line-height: 1;
  margin: 0;
  font-weight: 700;
}

h2 {
  margin-top: 12px;
  font-size: 24px;
  font-weight: 400;
  margin-left: 0.8vw;
}

a,
a:active {
  color: #1e448a;
  text-decoration: none;
  display: inline-block;
}

a:hover {
  border-bottom: 2px dotted #1e448a;
}
